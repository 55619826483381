// Image Splide
.frame-type-image{
    .frame-inner {
        padding-left: 50px;
        padding-right: 50px;
    }
}

.splide {
    &__arrow {
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY( -50% );
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        padding: 0;
        opacity: 0.7;
        background: transparent;


        &:hover {
            cursor: pointer;
            opacity: 1;
        }

        &:focus {
            outline: none;
        }

        &--prev {
            left: -50px;
            .arrow__icon {
                transform: rotate(-45deg);
            }
        }

        &--next {
            right: -50px;
            .arrow__icon {
                transform: rotate(135deg);
            }
        }
    }
}

.arrow__icon {
    display: block;
    width: 100%;
    height: 100%;
    border-top: 2px solid $primary;
    border-left: 2px solid $primary;
}

// Logo Splide

.logo-splide {
    .splide__slide {
        .image {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            padding: 0 1rem;

            @include media-breakpoint-up(xl) {
                padding: 0 2rem;
            }

            img {
                filter: grayscale(100%);
                transition: 0.15s ease-in-out;

                @include hover {
                    filter: grayscale(0%);
                }
            }
        }
    }
}
